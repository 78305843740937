/*frontend/src/index.css*/
/* General Styles */
.is-current {
    background-color: rgba(0, 0, 0, 0.08);
}

html {
    scroll-behavior: smooth;
}

.report-style-class {
    /* Remove or adjust any fixed width/height */
    /* Example:
    width: 800px;
    height: 600px;
    */
    
    /* Ensure the report fills its container */
    width: 100% !important;
    height: 100% !important;
    border: none; /* Optional: Remove borders for a seamless look */
}

/* Ensure the report container is responsive */
@media (max-width: 600px) {
    .report-style-class {
        height: 100%; /* Adjust height for smaller screens */
    }
}

